// Processing commands for ImageSections: https://docs.sixlabors.com/articles/ImageSectionsharp.web/processingcommands.html

import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";

import { useIsInEditMode } from "hooks";
import { BREAKPOINT_NUMBER } from "style/breakpoints";
import generateSrcSet, { commonSourceSets, noCropLargeSourceSet } from "style/generateSrcSet";

import { Image } from "./ImageSection.styled";
import ImageSectionProps from "./ImageSectionProps";

const ImageSection = (props: ImageSectionProps): ReactElement => {
    const isInEditMode = useIsInEditMode();
    const image = { ...props };

    const centerPosition = image.focalPoint ? image.focalPoint
        .split("|")
        .map((num) => Number(num) * 100) as [number, number] : undefined;

    if (image.url && !image.srcSet) {
        if (image.className?.includes("embedded")) {
            // If the image is embedded and has a certain size we need to generate a srcSet and set fixed image size
            if (
                (image.width && image.width > 0) ||
                (image.height && image.height > 0)
            ) {
                image.maxWidth = image.width;
                image.srcSet = generateSrcSet({
                    image: image.url,
                    content: [
                        {
                            width: image.width,
                            height: image.height,
                        },
                    ],
                    maxWidth: image.uploadedWidth,
                    maxHeight: image.uploadedHeight,
                });
            } else {
                image.srcSet = generateSrcSet({
                    image: image.url,
                    focalPoint: image.focalPoint,
                    content: [
                        {
                            width: 900,
                            breakpoint: BREAKPOINT_NUMBER.L,
                        },
                        {
                            width: 768,
                        },
                    ],
                    maxWidth: image.uploadedWidth,
                    maxHeight: image.uploadedHeight,
                });
            }
        } else {
            image.srcSet = generateSrcSet({
                image: image.url,
                focalPoint: image.focalPoint,
                content: image.keepAspectRatio ? noCropLargeSourceSet : commonSourceSets.large,
                maxWidth: image.uploadedWidth,
                maxHeight: image.uploadedHeight,
            });
        }
    }
    return (
        <ThemeProvider
            theme={{
                isInEditMode: isInEditMode
            }}
        >
            <Image $centerPosition={centerPosition} {...image} />
        </ThemeProvider>
    );
};

export default React.memo(ImageSection);
